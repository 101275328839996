import localFont from 'next/font/local'

const neueFrutigerWorld = localFont({
    src: [
        {
            path: '../fonts/NeueFrutigerWorld-Thin.ttf',
            weight: '100',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-ThinIt.ttf',
            weight: '100',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-UltLt.ttf',
            weight: '200',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-UltLtIt.ttf',
            weight: '200',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Light.ttf',
            weight: '300',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-LightIt.ttf',
            weight: '300',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Regular.ttf',
            weight: '400',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-It.ttf',
            weight: '400',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Medium.ttf',
            weight: '500',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-MediumIt.ttf',
            weight: '500',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Book.ttf',
            weight: '600',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-BookIt.ttf',
            weight: '600',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Bold.ttf',
            weight: '700',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-BoldIt.ttf',
            weight: '700',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Heavy.ttf',
            weight: '800',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-HeavyIt.ttf',
            weight: '800',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-Black.ttf',
            weight: '900',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-BlackIt.ttf',
            weight: '900',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-XtraBlk.ttf',
            weight: '950',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/NeueFrutigerWorld-XtraBlkIt.ttf',
            weight: '950',
            style: 'italic',
            display: 'swap',
        },
    ],
})
const gladoria = localFont({
    src: [
        {
            path: '../fonts/Gladiora-Thin.ttf',
            weight: '100',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-ThinItalic.ttf',
            weight: '100',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-ExtraLight.ttf',
            weight: '200',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-ExtraLightItalic.ttf',
            weight: '200',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-Light.ttf',
            weight: '300',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-LightItalic.ttf',
            weight: '300',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-Regular.ttf',
            weight: '400',
            style: 'normal',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-Italic.ttf',
            weight: '400',
            style: 'italic',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-Medium.ttf',
            weight: '500',
            style: 'normal',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-MediumItalic.ttf',
            weight: '500',
            style: 'italic',
            display: 'swap',
        },
        {
            path: '../fonts/Gladiora-SemiBold.ttf',
            weight: '600',
            style: 'normal',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-SemiBoldItalic.ttf',
            weight: '600',
            style: 'italic',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-Bold.ttf',
            weight: '700',
            style: 'normal',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-BoldItalic.ttf',
            weight: '700',
            style: 'italic',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-ExtraBold.ttf',
            weight: '800',
            style: 'normal',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-ExtraBoldItalic.ttf',
            weight: '800',
            style: 'italic',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-Black.ttf',
            weight: '900',
            style: 'normal',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-BlackItalic.ttf',
            weight: '900',
            style: 'italic',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-ExtraBlack.ttf',
            weight: '950',
            style: 'normal',
            display: 'swap',
        },

        {
            path: '../fonts/Gladiora-ExtraBlackItalic.ttf',
            weight: '950',
            style: 'italic',
            fisplay: 'swap',
        },
    ],
})

export const Fonts = () => {
    return (
        <style jsx global>{`
            :root {
                --neueFrutigerWorld-font: ${neueFrutigerWorld.style.fontFamily};
                --gladoria-font: ${gladoria.style.fontFamily};
            }
        `}</style>
    )
}
