import '@/styles/main.scss'
import { HakoApp } from '@hako/core'
import { Fonts } from '@/components/Fonts'
import React from 'react'

const App = (props) => {
    return (
        <>
            <Fonts />
            <HakoApp {...props} />
        </>
    )
}
export default App
